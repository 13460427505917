import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/icons/logo.svg";


const useStyles = makeStyles((theme) => ({
  termsContainer: {
    position: "relative",
    backgroundImage: `url(${logo})`, // Replace with the actual path to your background image
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    padding: theme.spacing(2), // Adjust padding as needed
   
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust opacity (0.3 for 30% opacity), // Adjust opacity as needed
    },
  },
}));

const Terms = () => {
    const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" >
        <img src={logo} height={200} width={300}/>
        <h1>Collabkare TERMS OF SERVICE</h1>
        <p>
          <strong>EFFECTIVE: August 20, 2022</strong>
        </p>
        <p>
          This document – the Terms & Conditions – (or "T&C", “Agreement”) is an
          agreement between you (the “User”, “Doctor”, “Health Care Provider”,
          “Patient”, “End-User”) and Wayu Health Pvt. Ltd. ("Wayu Health",
          “Company”). It contains conditions you agree to follow when using our
          mobile and web applications as well as our websites, including when
          you ask questions and when you view or input content on or into the
          Apps. The Wayu Health Privacy Policy Statement is officially part of
          these T&C, and can be accessed{" "}
          <a href="http://wayumd.com/support/terms.html" target="_blank">
            here
          </a>
          .
        </p>

        <p>
          When you register on Wayu Health’s Apps you verify that you have read
          and agree with the Terms & Conditions as well as the Privacy Policy.
        </p>

        <p>
          These T&C and the Privacy Policy together constitute a legal agreement
          (“Agreement”) between you – the “user” – and Wayu Health in connection
          with your visit to the Website and your use of the Wayu Health’s Apps
          including WayuMD, WayuMD For Doctors, and FirstStep.
        </p>

        <h4>We May Update this Agreement</h4>

        <p>
          These Terms & Conditions may change. Because your use of Wayu Health
          Apps and Website means you agree to these T&C’s, if we revise and
          update these T&C’s, your continued use of Wayu Health Apps and Website
          will mean that you accept those changes. You choose whether or not you
          use Wayu Health Apps and Website. If you do not agree to all of these
          Terms & Conditions, please do not use Wayu Health Apps and Website.
        </p>

        <h4>Applicability of Terms</h4>
        <p>The Terms and Conditions Agreement applies to you if you are:</p>
        <p>
          1. A medical practitioner or health care provider (whether an
          individual professional or an organization) or similar institution
          wishing to avail the services provided by Wayu Health Apps and
          website, authorised associates of such practitioners or institutions
          (“Health Care Provider”, “HCP”, “you” or “User”); or
          <br />
          <br />
          2. A patient, their guardian, representatives or affiliates,
          consulting with Health Care Provider(s) (HCP) through the Website or
          App (“Patient”, “End-User”, “you” or “User”); or
          <br />
          <br />
          3. Otherwise a user of the Website or Apps (“you” or “User”).
        </p>
        <p>
          This Agreement applies to all services made available by Wayu Health
          on their Website, and their mobile and web Apps.
        </p>

        <h4>Your Health, Your Choices:</h4>
        <p>
          We are dedicated to enabling and empowering you to make smart choices
          for your health and well-being. Our mission is to provide you with a
          platform that empowers you to seek personalised and pertinent health
          advice, and support your doctor-patient relationship by making it more
          accessible, affordable and intelligent.
        </p>

        <h4>Wayu Health Products and Services: </h4>
        <p>
          <b className="b">WayuMD For Doctors:</b> WayuMD offers Health Care
          Providers (“HCP”) a platform to set up and operate their
          online/virtual ‘clinics’ and offer their services to their patients.
          The platform includes features that allow doctors to maintain an
          online presence on the App, made available and accessible to other
          Users, answer patient queries, look up their patients medical profile
          and history during a consultation, and conduct paid consultations with
          their patients.
          <br />
          <br />
          <b className="b">WayuMD:</b> WayuMD (for Patients, “Users”) allows
          Users to create and maintain an online account with up-to-date medical
          profiles, create and maintain a Care Team of their doctors and/or
          HCPs, seek health advice and request consultations with their doctors
          and/or HCPs for a fee.
          <br />
          <br />
          <b className="b">FirstStep:</b> FirstStep is a paediatric app for
          parents to record, maintain and track the health and well-being of
          their child using features like personalised immunisation schedules,
          growth charts and developmental milestones covering a baby’s
          development in four major areas – cognitive, social & emotional,
          communication, and physical development. Our services may change from
          time to time, at the sole discretion of Wayu Health. The T&C will
          apply to your visit to and your use of the Website, and any & all Wayu
          Health Apps, to avail the services provided, and to all information
          provided by you on these platforms.
        </p>

        <h4>Use of FirstStep </h4>
        <p>
          FirstStep is a paediatric app for parents to record, maintain and
          track the health and well-being of their child using features like
          personalised immunisation schedules, growth charts and developmental
          milestones covering a baby’s development in four major areas –
          cognitive, social & emotional, communication, and physical
          development.
          <br />
          <br />
          The vaccination plan recommended in the FirstStep App is adopted from
          the online contents of the Indian Academy of Pediatrics and other such
          sources like the World Health Organisation (WHO) as well as the
          government recommended immunisation programs in respective countries.
          <br />
          <br />
          This app is purely a reminder service, and is meant to serve as an
          enabler for timely immunisations and capturing other relevant
          information regarding the child. The App does not take responsibility
          for the actual implementation of the plan by the user, or the accuracy
          and validity of the data entered/captured by the user.
        </p>

        <h4>Use of WayuMD App</h4>
        <p>
          WayuMD allows you to conduct an online consultation with your Doctor
          or HCP. As such, on the WayuMD platform, an HCP may provide virtual
          consults, diagnosis and prescriptions, as appropriate. You, the user,
          consent to Wayu Health transferring your consult queries to the HCP(s)
          selected and determined by you for the respective consult. You
          understand and agree that the information during these interactions is
          subject to our Privacy Policy.
          <br />
          <br />
          WayuMD, however, is not a substitute for a primary care doctor, or
          in-person health care interactions. You agree not to use WayuMD as a
          substitute for a primary care doctor or in- person doctor visits.
          Diagnosis and prescriptions provided via the WayuMD apps are at the
          sole discretion of the HCPs using the platform. You understand and
          agree that any issues, questions or concerns relating to these
          consults must be taken up directly with your HCPs.
          <br />
          <br />
          WayuMD is a platform for you to connect with your doctor and discuss
          topics related to your health and well-being. These Apps, and Wayu
          Health, do not take responsibility for the any advice given during the
          interactions on the Apps, and the agreement with and implementation of
          that advice is solely at the discretion of the Users.
        </p>

        <h4>
          Never use WayuMD (including Apps or website) in a potential or actual
          medical emergency.{" "}
        </h4>
        <br />
        <h4>Doctor-Patient Relationship </h4>
        <p>
          While our platform supports Doctor-Patient relationships, we do not
          create nor are responsible for doctor-patient relationships.
          <br />
          <br />
          During your use of our Apps and website services, you may come across
          and/or receive content - text, data, graphics, images, information,
          suggestions, guidance, and other material – including information
          provided in response to your particular query by the your HCP(s).
          However, the provision or exchange of such information does not create
          a licensed doctor-patient relationship, between Wayu Health and you.
        </p>

        <h4>Health Care Providers on WayuMD</h4>
        <p>
          While Wayu Health takes steps to verify the medical registration of
          doctors and HCPs using our platform to provide their services, we make
          no guarantees, representations or warranties, whether expressed or
          implied, with respect to professional qualifications, quality of work,
          expertise or other information provided by the HCPs on their profiles.
          Wayu Health does not recommend or endorse any specific individual
          and/or HCP(s) that may be mentioned on the website or Apps. In no
          event will Wayu Health be liable to the user or anyone else for any
          decision made or action taken by the user or anyone else on basis of
          the information provided on the website and/or Apps. No medical, legal
          or any other decision shall be based solely upon the information
          provided through the website or Apps.
        </p>

        <h4>Consult Fees on WayuMD and other Paid Services</h4>
        <p>
          WayuMD is a platform that allows you to connect with and consult with
          your doctor(s) and/or HCPs. Your HCP may have a fees associated with
          the consultations they provide you on WayuMD. The Apps provide a
          payment service to collect the consult fees from you on behalf of the
          HCP. Your payment is processed through secure third-party payment
          gateway services.
          <br />
          <br />
          When you use WayuMD or other paid services provided via our Apps or
          website, you understand that Wayu Health will collect payment from
          such services from you on behalf of the HCPs providing you the
          respective services. You acknowledge that Wayu Health is not liable
          for the treatment or advice given during the consult (or other paid
          service) and cannot be held liable.
        </p>

        <h4>Cancellations and Refunds</h4>
        <p>
          Under certain circumstances, you may be able to cancel a transaction
          on WayuMD and be eligible for a full refund of your payment, including
          when:
          <br />
          <br />
          1. You submitted a consult to an HCP, and they have not yet opened the
          message or responded to your query.
          <br />
          2. The HCP declined your consult request for any reason.
          <br />
          3. A technical glitch on the Apps or website caused your consult to
          not be submitted to the HCP.
          <br />
          However, we cannot address disputes or concerns you may have during or
          after a consult with your HCPs. Any requests for or expectations of
          refunds in this case must be taken up directly with your HCPs
        </p>

        <h4>Medical Advice </h4>
        <p>
          The site or app and any related communication does not guarantee the
          accuracy or authenticity of the information. Use of any information
          appearing on the website or Apps is solely at the user's own risk.
          <br />
          <br />
          The information and communication through the website or Apps is not
          intended to be a substitute for professional medical advice, diagnosis
          or treatment. Always seek the advice of a physician or other qualified
          Health Care Provider with any questions the user may have regarding a
          medical condition. Wayu Health does not recommend or endorse any
          specific tests, physicians, products, procedures, opinions, or other
          information that may be mentioned on the website or Apps. The website
          or Apps may contain health- or medical-related materials which the
          user may find offensive, then they may not want to use our website or
          Apps.
          <br />
          <br />
          In no event will Wayu Health be liable to the user or anyone else for
          any decision made or action taken by the user or anyone else on basis
          of the information provided on the website and/or Apps. No medical,
          legal or any other decision shall be based solely upon the information
          provided through this wesite or Apps.
        </p>

        <h4>Drugs and Medicines Policy</h4>
        <p>
          The information about drugs, including generic drugs and vaccines,
          contained in the Apps and website is general in nature and is intended
          for use as an informational aid. It does not cover all possible uses,
          actions, precautions, side effects, or interactions of these
          medicines, nor is the information intended as medical advice for
          individual problems or for making an evaluation as to the risks &
          benefits of taking a particular drug. All such actions should be taken
          only under the advice of a qualified medical professional.
        </p>

        <h4>Payments & Purchases</h4>
        <p>
          You may decide to buy plans, products and services through our website
          and/or Apps. Payment for such purchases is your responsibility and can
          be executed through different means - including (but not limited to)
          credit and debit cards, gift coupons, third-party payment services and
          gateways (incl. CCAvenue, Payeezy, Paypal) and in some cases cash.
          <br />
          <br />
          You are responsible for providing valid payment information (for any
          of the payment means including but not limited to those mentioned
          above) at the time you purchase any plans, products and services. You
          represent and warrant that you are an authorized user of such payment
          means, and you agree to pay all charges resulting from your
          transaction at the prices then in effect. You agree that Wayu Health
          may pass your payment information (e.g credit card) and related
          personally identifiable information to its designated service
          provider(s) for their use in charging you for appropriate services
          utilized. The ultimate responsibility of payment lies with the
          purchaser of the plans, products and/or services.
          <br />
          <br />
          The charges for any plans, products or services may attract taxes,
          cess or any other charges as levied by competent authorities in a
          given region. These additional charges will be borne by the purchaser
          and will be paid at the time of purchase.
          <br />
          Wayu Health reserves the right to modify or terminate membership
          plans, change prices, or institute new charges for any product or
          service at any time, with at least thirty (30) days' notice where
          applicable to existing customers.
        </p>
        <h4>Communications to The User</h4>
        <p>
          When you register on Wayu Health website and/or Apps, we may
          communicate with you, including by sending information,
          correspondence, and notices to you. These communications may be sent
          via email, SMS (text message), push notification, or otherwise using
          contact information associated with your account, including
          information provided when you register or update information in your
          Account profile. These communications will not contain Personal Health
          Information.
          <br />
          <br />
          When the user signs up for our email newsletters they will receive
          additional promotional emails from Wayu Health or our Sponsors,
          Partners and Associates. In order to subscribe to Wayu Health
          newsletters via email, we need the user’s contact information, like
          name and email address. You can opt out of promotional communications
          and control your communications preferences through your Account
          settings.
          <br />
          <br />
          If you experience difficulties with our automated unsubscribe service,
          please contact us. Wayu Health will manually unsubscribe you from that
          newsletter. In some cases, when you click on a link or an
          advertisement on our website or Apps, in an e-mail or newsletter, your
          browser may be momentarily directed to the website of a third party
          which, acting on behalf of Wayu Health notes or "counts" your response
          to the e-mail or newsletter before re-directing your browser to your
          selected destination; this re-direction process may not be apparent to
          you.
        </p>

        <h4>Your Account Security and Privacy</h4>
        <p>
          Accurate and complete registration information is required to use Wayu
          Health Apps and website. You, the user, are responsible for
          maintaining the confidentiality of the your account access information
          and password. You are responsible for the security of your passwords
          and for any use of your account. You must immediately notify us of any
          unauthorized use of your password or account.
          <br />
          <br />
          You are not permitted to allow any other person or entity to use your
          identity for using any of Wayu Health’s Apps and/or Websites.
          <br />
          <br />
          You may add dependants to your account on Wayu Health Apps and
          website. If you seek consults and/or access your dependants’ records
          through your Wayu account, you are responsible for the records of your
          dependants and all obligations that your dependants would have had had
          they maintained their own individual Wayu Health account.
          <br />
          To send us an email, use the "Contact Us"/Support/feedback links
          located on our website and Apps. You grant Wayu Health and all other
          persons or entities involved in the operation of the website and Apps
          the right to transmit, monitor, retrieve, store, and use your
          information in connection with the operation of the website and Apps.
          Wayu Health cannot and does not assume any responsibility or liability
          for any information you submit, or your or third parties' use or
          misuse of information transmitted or received using Wayu Health tools
          and services.
        </p>

        <h4>International Users Consent to Terms and Policies</h4>
        <p>
          We make no representation that all products, Content or Services
          described on or available through the Apps, are appropriate or
          available for use in locations outside India. Registered Users and
          Visitors access our Content and Services on their own initiative and
          are responsible for compliance with local laws. We make no claim that
          Content or Services are appropriate or may be downloaded outside of
          India. Personal information ("Information") that is submitted to Wayu
          Health Apps and website will be collected, processed, stored,
          disclosed and disposed of in accordance with applicable law and our
          Privacy Policy. If you are a non-Indian member, you acknowledge and
          agree that Wayu Health may collect and use your Information and
          disclose it to other entities outside your resident jurisdiction. In
          addition, such Information may be stored on servers located outside
          your resident jurisdiction. Indian law may not provide the degree of
          protection for Information that is available in other countries. By
          providing us with your Information, you acknowledge that you consent
          to the transfer of such Information outside your resident jurisdiction
          as detailed in these T&C and Privacy Policy. If you do not consent to
          such transfer, you may not use our Apps or website. The product
          information provided on Wayu Health is presently intended only for
          residents of India, and may have different product labeling and
          disclosure requirements in different countries.
        </p>

        <h4>Terms specific to Health Care Providers</h4>
        <p>
          WayuMD for Doctors provides Health Care Providers (HCP) a platform to
          set up an online presence and use the Apps to connect with their
          patients, and help those in need of health and well-being advice.
          <br />
          <br />
          You, the HCP, hereby represent and warrant that you will use the Wayu
          Health Apps and services in accordance with applicable laws. Any
          contravention of applicable law as a result of your use of these
          Services is your sole responsibility, and Wayu Health accepts no
          liability for the same.
        </p>
        <h4>HCP Online Profile </h4>
        <p>
          The HCP is solely responsible for the authenticity and validity of the
          information they provide on their respective online profiles on Wayu
          Health Apps and websites. HCPs agree to provide Wayu Health with the
          required information during the verification checks at the time of
          registration or any other verification check done at a later date.
          <br />
          <br />
          The HCP understands that the information provided by them via the
          WayuMD for Doctors App (in particular the profile contents), or
          through the Company, may be made available to the general public, Wayu
          Health App users and that they have no objection to the same.
          <br />
          <br />
          The HCP warrants that you are fully entitled under law to upload all
          content uploaded by you as part of your profile or otherwise while
          using any of Wayu Health Apps and website. You confirm that no such
          content breaches any third party rights, including intellectual
          property rights. Should we be made aware of a breach of the foregoing
          representation, Wayu Health may modify or delete parts of your posted
          information at its sole discretion with or without any notifications
          to you, the HCP.
          <br />
          <br />
          If Wayu Health determines that the HCP has provided inaccurate
          information or enabled fraudulent activity, Wayu Health reserves the
          right to immediately suspend any accounts on any of Wayu Health Apps
          and/or website. We may also make such declaration on the Apps and/or
          website against your Name, profile and/or listing for the protection
          of our business and in the interests of Users. The HCP shall be liable
          to indemnify Wayu Health for any losses incurred as a result of their
          misrepresentations or fraudulent activity that has adversely affected
          Wayu Health and/or its Users.{" "}
        </p>
        <h4>HCP Search Listing </h4>
        <p>
          In order for patients to find a HCP, WayuMD offers a search function.
          All HCPs on the Wayu platform who satisfy the search criteria are
          listed on the search results. The search results listing do not
          represent any fixed objective ranking or endorsement by Wayu Health.
          The rankings are based on automated computations that include but are
          not limited to the relevance with search criteria, HCPs’ availability
          and use of the Apps. We cannot be held responsible for the accuracy
          and the relevancy of the listing of the HCPs on the Apps or websites.
          Wayu Health is not liable and responsible for the ranking of the HCPs
          on external websites and search engines.{" "}
        </p>
        <h4>HCP Online Consults</h4>

        <p>
          HCP is solely responsible for the interactions with users via the Wayu
          Health Apps and website. As a Health Care Provider, you agree to
          perform your services and fulfill your obligations towards your
          patient (user) to the best of your skills and ability. <br />
          <br />
          When any User contacts the HCP via the WayuMD App, only the HCP shall
          be allowed to perform the services for the User, and the HCP may under
          no circumstances transfer the performance of their Services to any
          other person, whether under their supervision or not. The HCP accepts
          all responsibility and liability for the use of WayuMD Apps and
          website, including the performance of its services by any other party
          claiming to be the HCP and agrees to indemnify Wayu Health against any
          claim or loss that may be faced by Wayu Health as a consequence of
          such use.
          <br />
          <br />
          Any content, message or communication sent by a User to the HCP via
          Wayu Health Apps or website is based solely on information uploaded by
          the Users. Wayu Health is not responsible for any incompleteness or
          inaccuracy of such information, including if, as a result of the
          inaccuracy, a communication is sent to an unintended recipient.
        </p>
        <h4>HCP Consult Fees collection and transfer </h4>
        <p>
          HCPs may charge a consultation fee for consults or interactions
          conducted with patients via Wayu Health Apps and website. The HCPs
          agree to allow Wayu Health to collect such payments on behalf of the
          HCP. Wayu Health will remit the amount to the HCP and deduct a certain
          portion of the amount paid as its fees for providing the services.{" "}
          <br />
          <br />
          Wayu Health shall remit the fees to the HCP in accordance with the
          terms agreed between the HCPs and Wayu Health. <br />
          <br />
          Wayu Health reserves the right to revise the fee terms at any time, at
          their discretion. The HCPs continued use of the services and WayuMD
          for Doctors App shall constitute their consent to such revision.
        </p>
        <h4>HCP Responsible Use of the WayuMD App</h4>
        <p>
          HCPs hereby agrees that they shall use the WayuMD for Doctors App for
          the purpose specified in these Terms & Conditions and shall not use
          the WayuMD for Doctors App for any unauthorized and unlawful purpose,
          including impersonating another person. <br />
          <br />
          HCPs shall promptly renew their licenses required to provide medical
          services and notify Wayu Health of any updates or changes to their
          medical registration numbers and/or licenses.
          <br />
          <br />
          The HCP confirms and warrants that they: <br />
          <br />
          1. are qualified to provide medical services within the territory of
          India
          <br />
          2. have obtained all licenses as required by law to provide medical
          services and have not committed any act or omission that might
          prejudice its continuance or renewal <br />
          3. have provided on Wayu Health’s Apps and Website true, accurate,
          complete and up to date details about their qualification and
          credentials The HCP agrees that they shall abide by the applicable
          medical regulations including the code of professional ethics as
          prescribed under applicable laws, at all times.
        </p>

        <h4>HCP Confidentiality of Patient Information </h4>
        <p>
          The HCP agrees to hold all information provided by a User to them in
          strictest confidence , under all circumstances. HCP agrees that they
          shall not disclose any information or documentation provided by a User
          to any other person, nor shall they allow, by act or omission, such
          information or documentation to be acquired by any other person.{" "}
        </p>
        <h4>HCP Termination of Account</h4>
        <p>
          Wayu Health reserves the right to terminate any account of HCP on any
          Apps and website including when:
          <br />
          <br />
          1. The HCP breaches any terms as listed in the Terms & Conditions, the
          Agreement between Wayu Health & HCP, and/or privacy policy or
          applicable laws. <br />
          2. Wayu Health is unable to verify or authenticate any information
          provided to it by the HCP. <br />
          3. Wayu Health in its sole and absolute discretion believes that
          actions of the HCP may cause legal liability for Wayu Health and/or
          other Users, and/or may adversely affect the services rendered by Wayu
          Health.
        </p>
        <h4>Acknowledgements</h4>
        <p>
          We have attempted to provide acknowledgement of sources as references
          for text, but there is likelihood that several acknowledgements are
          not mentioned. The Company is certainly willing to correct omissions
          of citations & encourage our readers to email us with further
          information regarding the sources. <br />
          <br />
          If the user believes any materials accessible on or from the
          website/Apps infringe their copyright, they may request removal of
          those materials (or access thereto) from the website/Apps by
          contacting Wayu Health and providing the following information: <br />
          <br />
        </p>
        <ul>
          <li>
            Identification of the copyrighted work that the user believes to be
            infringed. Please describe the work, and where possible include a
            copy or the location (e.g., URL) of an authorized version of the
            work.
          </li>
          <li>
            Identification of the material that the user believes to be
            infringing and its location. Please describe the material, and
            provide us with its URL or any other pertinent information that will
            allow us to locate the material.
          </li>
          <li>
            User’s name, address, telephone number and (if available) e-mail
            address.{" "}
          </li>
          <li>
            A statement that the user has a good faith belief that the complaint
            of use of the materials is not authorized by the copyright owner,
            its agent, or the law.{" "}
          </li>
          <li>
            A statement that the information that the user has supplied is
            accurate, and indicating that "under penalty of perjury," the user
            is the copyright owner or is authorized to act on the copyright
            owner's behalf.{" "}
          </li>
          <li>
            A signature or the electronic equivalent from the copyright holder
            or authorized representative.
          </li>
        </ul>
        <br />
        <br />
        <p>
          {" "}
          In an effort to protect the rights of copyright owners, Wayu Health
          maintains a policy for the termination, in appropriate circumstances,
          of subscribers and account holders of the website/Apps who are repeat
          infringers.
        </p>

        <h4>Copyrights</h4>
        <p>
          Information on the Wayu Health website and Apps is for personal use &
          may not be sold or redistributed. To reprint or electronically
          reproduce any document or graphic in whole or in part for any reason
          is expressly prohibited, unless prior written consent is obtained from
          Wayu Health. <br />
          <br />
          The Content is protected by copyright under both Indian and foreign
          laws. Title to the Content remains with Wayu Health or its licensors.
          Any use of the Content not expressly permitted by these Terms &
          Conditions is a breach of these T&C and may violate copyright,
          trademark, and other laws. Content and features are subject to change
          or terminate without notice in the editorial discretion of Wayu
          Health. All rights not expressly granted herein are reserved to Wayu
          Health and its licensors. <br />
          <br />
          All content on the Wayu Health website and Apps including designs,
          text, graphics, pictures, information, applications, software, music,
          sound and other files, and their selection and arrangement (the "Site
          Content") are the sole proprietary property of Wayu Health unless
          otherwise indicated. You do not have the right to modify, copy,
          perform, distribute, frame, reproduce, republish, upload, download,
          scrape, display, post, transmit, or sell in any form or by any means,
          in whole or in part, without Wayu Health's prior written consent as
          they are protected under applicable copyright laws and treaty
          provisions (including but not limited to applicable intellectual
          property laws).{" "}
        </p>

        <h4>Trademarks</h4>

        <p>
          We also own the names we use for our products and services on Wayu
          Health, and these names are protected by trademark laws in India. and
          internationally. Any use of our trademarks require our prior written
          approval.{" "}
        </p>
        <h4>User Profile and Privacy Policy</h4>

        <p>
          Our website's and Apps' registration and query requires users to give
          us personal information such as their name, email and demographic
          information such as their address and telephone number. The user's
          personal information is used to contact the visitor as and when
          necessary. This personal information also allows us to inform the
          users about updates to the service and further additions on Wayu
          Health website and Apps. We collect personal information from user
          when they register on Wayu Health’s website or Apps, or when they
          submit a query to us. We maintain this information as private to the
          best of our ability.
        </p>

        <h4>User Conduct</h4>

        <p>
          User shall use the website and Apps for lawful purposes only. User
          shall not post or transmit through Wayu Health website or Apps, any
          material which violates or infringes in any way upon the rights of
          others, which is unlawful, threatening, abusive, defamatory, invasive
          of privacy or publicity rights, vulgar, obscene, profane or otherwise
          objectionable. User shall not use Wayu Health website or Apps to
          advertise or perform any commercial solicitation. <br />
          <br />
          The user also understands that the company cannot & does not guarantee
          or warrant that files available for downloading through the service
          will be free of infection or viruses, worms or other code that
          manifest contaminating or destructive properties. <br />
          <br />
          You may not access the website or Apps for any benchmarking or
          competitive purposes such as monitoring its availability, performance
          or functionality. You may not resell, redistribute, or put to any
          commercial use, any content or information from this app or site.{" "}
        </p>

        <h4>User License</h4>

        <p>
          Provided that you are eligible for use of the website and/or Apps,
          Wayu Health hereby grants you a limited license to access and use the
          website and/or Apps and the Site Content solely for your personal,
          non-commercial use. You may not republish Site Content on any
          internet, intranet or extranet site or incorporate the information in
          any other database or compilation, and any other use of the Site
          Content is strictly prohibited. Unauthorized use may violate
          applicable laws including copyright and trademark laws and applicable
          communications regulations and statutes. Unless explicitly stated
          here, nothing in these Terms & Conditions shall be construed as
          conferring any license to intellectual property rights. This license
          is revocable at any time without notice and with or without cause.{" "}
        </p>

        <h4>Linking to Us</h4>

        <p>
          Usually, we don't mind if you have a simple link from your website to
          ours. However, you must first ask our permission if you intend to
          frame our site or incorporate pieces of it into a different site or
          product in a way that is not clear to our users. You are not allowed
          to link to us if you engage in illegal, obscene, or offensive content,
          or if the link in any way has a negative impact on our reputation.{" "}
        </p>

        <h4>Liability </h4>

        <p>
          The use of the Wayu Health website and Apps and the Content is at your
          own risk. The providers of this website and Apps disclaim any & all
          liabilities arising directly or indirectly to anybody as a result of
          use of this website and/or Apps.{" "}
        </p>

        <h4>Indemnity</h4>

        <p>
          You agree to defend, indemnify, and hold Wayu Health, its officers,
          directors, employees, agents, licensors, and suppliers, harmless from
          and against any claims, actions or demands, liabilities and
          settlements including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from, your
          violation of these Terms and Conditions.
          <br />
          <br />
          In no event shall Wayu Health and its parent organizations,
          subsidiaries and affiliates, and each of their directors, officers,
          agents, consultants, contractors, partners, employees, suppliers and
          sponsors be liable to you or any third person for any indirect,
          consequential, punitive, special, incidental or exemplary damages of
          any type or kind, including loss of data, revenue, profits, or other
          economic advantage, arising out of or in any way connected with the
          website and/or apps, including for any content obtained through the
          service, any error, omission, interruption, deletion, inaccuracy,
          defect, delay in operation or transmission, communications line
          failure, technical malfunction or other problems of any telephone
          network or service, computer systems, servers or providers, computer
          equipment, software, failure or email or players on account of
          technical problems or traffic congestion on the internet or at the app
          or website or combination thereof, even if Wayu Health has been
          previously advised of such damages or has been previously advised of
          the possibility of such damages. Under no circumstances will Wayu
          Health be responsible for any loss or damage, including any loss or
          damage to any user content or personal injury or death, resulting from
          anyone's use of the Apps, website or any user content posted on or
          through the Apps or website or transmitted to users, or any
          interaction between users of the app or website, whether online or
          offline even if Wayu Health has been previously advised of such
          damages or has been previously advised of the possibility of such
          damages. <br />
          <br />
          Wayu Health does not represent or warrant that software, content or
          materials on the Apps or website are accurate, complete, reliable,
          current or error-free or that the Apps, website or its servers, or any
          platform applications are free of viruses or other harmful components.
          The Apps, website and all content are provided to you strictly on an
          "as is" basis. Therefore, you should exercise caution in the use and
          downloading of any such software, content or materials and use
          industry-recognized software to detect and disinfect viruses. Without
          limiting the foregoing, you understand and agree that you download or
          otherwise obtain content, material, data or software from or through
          the Apps or website at your own discretion and risk and that you will
          be solely responsible for your use thereof and any injury or damage to
          your or to any person's computer, or other hardware or software,
          related to or resulting from using or downloading materials in
          connection with the app or website, theft or destruction or
          unauthorized access to, or alteration of the app or website, loss of
          data or other harm of any kind that may result. The app or website may
          be temporarily unavailable from time to time for maintenance or other
          reasons.{" "}
        </p>

        <h4>Force Majeure</h4>
        <p>
          Wayu Health shall not be liable for any failure or unavailability of
          the app or site as a result of the loss or destruction of data, the
          deletion or corruption of storage media, power failures,
          unavailability of suppliers or any other event beyond its control.
        </p>
        <h4>Invalidity</h4>
        <p>
          If any one or more of the provisions contained in the T&C Agreement
          should be determined to be invalid, illegal or unenforceable in any
          respect, the validity, legality and enforceability of the remaining
          provisions of the Agreement shall not in any way be affected or
          impaired thereby.
        </p>
        <h4>Governing Law</h4>
        <p>
          This Agreement shall in all respects be governed by and be construed
          in accordance with the laws of the Gurgaon, Haryana and applicable
          laws of India therein.{" "}
        </p>
      </Container>
    </React.Fragment>
  );
};
export default Terms;
