import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import useWindowSize from 'hooks/useWindowSize';
import PrivateSection from "routes/PrivateSection";
import PublicRoutes from "routes/PublicRoutes";
import { connect } from "react-redux";
import { jwtDecode } from 'jwt-decode';


function Routes({ isAuthenticated, userRole }) {
  const { pathname } = useLocation();
  let [isAuth, setIsAuth] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [width, height] = useWindowSize();
  
  useEffect(() => {
      let token = sessionStorage.getItem('access_token');
    isAuth = checkIsValidToken(token);
    setIsAuth(isAuth);
    console.log(isAuth,"---isAuth",token,isAuthenticated)
  }, []);

  
const checkIsValidToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.exp * 1000 > Date.now();
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return isAuthenticated ? (
    <PrivateSection userRole={userRole} />
  ) : (
    <PublicRoutes />
  );
  // return <PublicRoutes />
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
  userRole: state.userRole,
});

export default connect(mapStateToProps)(Routes);

