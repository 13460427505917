import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "react-jss";
import Theme from "./resources/themes";
import Routes from "routes";
import "./index.css";
import store from "../src/redux/store";
import { Provider } from "react-redux";
// import Start from "components/Start";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <Provider store={store}>
          <Router>
            <Routes />
          </Router>
        </Provider>
      </ThemeProvider>
      ,
    </div>
  );
}

export default App;
