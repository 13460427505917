import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Paper,
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  Link,
} from "@mui/material";
import Img1 from "../../assets/icons/Personal-detalis.svg";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  description: Yup.string().required("Description is required"),
});

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form Values:", values);
    },
  });

  return (
    <div className="bookDemo">
      <div className="left-image-container">
        <Box
          component="img"
          src={Img1} // Replace with the URL of your image
          alt="Contact Us"
          sx={{ width: "100%",height:"100%", objectFit: "cover" }}
        />
      </div>
      <div className="right-form-container">
        {/* Right side form */}
        <Paper elevation={3} sx={{ padding: 4, flex: 1, boxShadow:"none" }}>
          <Typography variant="h5" component="div" gutterBottom>
            Contact Us
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  id="description"
                  name="description"
                  label="Description"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent={"flex-end"}
                  sx={{ marginTop: "15px" }}
                >
                  <div>
                    <Link
                      href="/signup"
                      variant="body2"
                      style={{ color: "#007AFF", marginLeft: "90px" }}
                    >
                      Signup
                    </Link>
                  </div>
                  <div>
                    <Button type="submit" fullWidth variant="contained">
                      Submit
                    </Button>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default ContactForm;
