import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BASE_URL, updateUserPassword, otpAuthenticator } from "apiServices/apiConfig"; // Assuming you have an API endpoint for updating user password
import { putDataPublic } from "resources/utilities";
import CollabKareSnackbar from "components/Snackbar";
import logo from "../../assets/icons/logo.svg";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const org = urlParams.get("org");
  const emailParam = urlParams.get("email");
  const otp = urlParams.get("otp_code");
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: emailParam || "", // Set email value from URL parameter
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
      newPassword: Yup.string()
        .required("New Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: async (values, { resetForm }) => {
      updatePassword(values);
      resetForm();
    },
  });

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({ msg: "", msgType: "" });
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const updatePassword = async (values) => {
    let url = BASE_URL + updateUserPassword;

    const data = {
      email: emailParam,
      otp_code: otp,
      password: values.newPassword,
      organization: org,
    };

    putDataPublic(url,data, function (res) {
      if (res && res.type === "success" && res.data!='Requested OTP Is Expired. Sent Email Again.') {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: "Password updated successfully.",
          msgType: "success",
        });
        setTimeout(() => {
          history.push("/login");
        }, 3000);
      } else {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: res.data || "An error occurred",
          msgType: "error",
        });
      }
      setShowLoader(false);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.paper} elevation={10}>
        <img
          className={classes.center}
          src={logo}
          style={{ width: "292px", maxHeight: "115px" }}
        />
        <br />
      </div>
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: "center", marginBottom: "25px" }}
            >
              Update Your Password
            </Typography>
            <form
              className={classes.form}
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    size="small"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      size="small"
                      autoComplete="new-password"
                      error={
                        formik.touched.newPassword &&
                        Boolean(formik.errors.newPassword)
                      }
                      helperText={
                        formik.touched.newPassword && formik.errors.newPassword
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={95}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    size="small"
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    onPaste={(e) => e.preventDefault()}
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="outlined"
                style={{
                  width: "100%",
                  backgroundColor: "#007AFF",
                  color: "white",
                  float: "right",
                  margin: "auto",
                  marginTop: "10px",
                }}
                className={classes.submit}
                disabled={showLoader}
              >
                {showLoader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login" style={{ color: "rgb(0, 122, 189)" }}>
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <p style={{ textAlign: "center" }}>
            Enter your email address and new password to update your password.
          </p>
        </Container>
        <CollabKareSnackbar
          open={showSnackbar}
          setOpen={setShowSnackbar}
          message={snackbarMsg.msg}
          messageType={snackbarMsg.msgType}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
