import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  CssBaseline,
  Paper,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./login.css";
import { functions } from "auth/firebase";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Cookies from "universal-cookie";
// import { signInWithEmailAndPassword } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import CloseIcon from "@material-ui/icons/Close";
import OtpTimer from "otp-timer";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { loginAuth } from "../../redux/action-creators/auth";
import logo from "../../assets/icons/logo.svg";
import sideImage from "../../assets/icons/Subtle-Prism-1.svg";
import welcome from "../../assets/icons/welcome.svg";
import { useHistory } from "react-router-dom";
import SearchComponent from "components/Orginization";
import PhonePng from "../../assets/icons/send-OTP-Google-app.png";
import CollabKareSnackbar from "components/Snackbar";
import { sendOtp,BASE_URL,otpAuthenticator,userLogin } from "apiServices/apiConfig"; // Assuming you have an API endpoint for updating user password
import { getDataPublic,putDataPublic } from "resources/utilities";
import axios from "axios";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${sideImage})`,
    // height: '100vh',
  },
  image: {
    background: `url(${sideImage})`,
    backgroundOrigin: "initial",
    backgroundAttachment: "fixed",
    backgroundClip: "initial",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundColor: "#fafafa",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "104%",
  },
  sideimage: {
    textAlign: "center",

    backgroundImage: `url(${welcome})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#fff",
    backgroundPosition: "center 93px",
    backgroundSize: "370px",
    border: "1px solid #ebebeb",
  },
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  margin: {
    margin: theme.spacing(0),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },

  paper: {
    backgroundColor: "white",
    padding: theme.spacing(3),
    border: "1px solid #fff",

    borderRadius: "0px 12px 12px 0px",

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
      borderRadius: "0px 12px 12px 0px",
    },
  },
  layout: {
    marginTop: theme.spacing(1),
    width: "auto !important",
    margin: "100px auto 71px",

    [theme.breakpoints.down(583)]: {
      marginTop: theme.spacing(8),
      width: 400,
      margin: "100px auto 71px",
      padding: "0",
    },
    [theme.breakpoints.down(400)]: {
      width: "auto",
      margin: "100px auto 71px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
      width: "800px !important",
      margin: "100px auto 71px",
      padding: "0",
    },
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bu: {
    background: "white",
    color: "black",
    paddingTop: "3px",
    paddingBottom: "3px",
    marginLeft: "-7px",
    marginRight: "-7px",
  },
  logintitle: {
    marginTop: 0,
    fontSize: 12,
    display: "none",
    [theme.breakpoints.up(767)]: {
      display: "block",
      marginTop: 0,
      fontSize: 12,
    },
  },
  loginsubtitle: {
    fontSize: 20,
    marginTop: 0,
    display: "none",
    [theme.breakpoints.up(767)]: {
      display: "block ",
      marginTop: "0",
    },
  },
  redirctsignup: {
    fontSize: 16,
    marginTop: 0,
    display: "none",
    [theme.breakpoints.up(767)]: {
      fontSize: 16,
      display: "block ",
      marginTop: 280,
    },
  },
  sigg: {
    display: "block",

    [theme.breakpoints.up(767)]: {
      display: "none ",
    },
  },
}));

const Login = () => {
  const cookies = new Cookies();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({ msgType: "", msg: "" });
  const [showLoader, setShowLoader] = useState(false);
  const [messageType, setMessageType] = useState("success");
  const [showSign, setShowSign] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState("");
  let [orgImage, setOrgImage] = useState(null);
  const [showOtp, setShowOtp] = useState(false);
  const [otpVal, setOtpVal] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email  is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setShowLoader(true);
      let payload = {
        client_id: "sand_man",
        username: values.email,
        password: values.password,
        grant_type: "password",
        client_secret: "eddc74f8-9dd7-4915-94e2-3106fbcad573",
      };
      handleValidateLogin(payload);
    },
  });

  useEffect(() => {
    let org = cookies.get("organization");
    if (org) {
      setSelectedOrg(org);
      setShowSign(true);
    } else {
      setShowSign(false);
    }
  }, []);

  const handleValidateLogin = async (data) => {
    try {
      let stringify = qs.stringify(data);

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${userLogin}${selectedOrg}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // Specify content type for URL encoded data
        },
        data: stringify, // Pass the URL encoded data
      };

        const response = await axios.request(config); // Send POST request
        setShowLoader(false);
        if (response?.data?.access_token) {
           sessionStorage.setItem("tempAccess",response?.data)
          setShowOtp(true);
          sendOtpVerification();
        }else{
          setShowLoader(false);
          setShowSnackbar(true);
          setSnackbarMsg({ msg: response?.data?.error_description, msgType: "error" });
        }
      
    } catch (error) {
      setShowLoader(false);
      setShowSnackbar(true);
      setSnackbarMsg({ msg: error.message, msgType: "error" });
    }
  };

  const sendOtpVerification = async () => {
    let orgName = cookies.get("organization");
    let url =
      BASE_URL +
      "/realms/" +
      orgName +
      `/emails/${formik?.values?.email}` +
      sendOtp;

    getDataPublic(url, function (res) {
      if (res && res.type == "success") {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: res.data,
          msgType: "success",
        });
      } else {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: "An error occurred !!! Please try again",
          msgType: "error",
        });
      }
      setShowLoader(false);
    });
  };

  const handleotpsubmit = async () => {
    let orgName = cookies.get("organization");
    setShowLoader(true);
    let url = otpAuthenticator;

    const data = {
      email: formik?.values?.email,
      otp_code: otpVal,
      organization: orgName,
    };

    putDataPublic(url,data, async function (res) {
      if (res && res.type === "success" ) {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: res.data,
          msgType: "success",
        });
        let access = sessionStorage.getItem('tempAccess')
        sessionStorage.setItem("access",access)
        sessionStorage.setItem("user",formik?.values?.email)
        const userCredential = await dispatch(
          loginAuth(formik?.values?.email)
        );
        if(userCredential){
          sessionStorage.setItem("access_token",userCredential?.access_token)
        }
          history.push("/dashboard");
       
      } else {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: res.data || "An error occurred",
          msgType: "error",
        });
      }
      setShowLoader(false);
    });
  };

  const login = async () => {
    const auth = firebase.auth();
    auth.tenantId = selectedOrg;
    const verifyToken = functions.httpsCallable("verifyToken");

    let email = formik?.values?.email;
    let password = formik?.values?.password;

    try {
      // Call the loginAPI action from authActions.js
      const userCredential = await dispatch(
        loginAuth(email, password, selectedOrg)
      );
      const { data: verificationResult } = await verifyToken({
        idToken: userCredential._tokenResponse.idToken,
      });

      if (verificationResult.valid) {
        // Redirect to the dashboard
        history.push("/dashboard");
      } else {
        // Token is not valid or has expired, redirect to login
        setShowLoader(false);
        setShowSnackbar(true);
        setSnackbarMsg({ msg: "session Timeout", msgType: "error" });
        history.push("/login");
      }

      // Redirect to the dashboard
    } catch (error) {
      // Handle login failure or other errors
      setShowLoader(false);
      setShowSnackbar(true);
      setSnackbarMsg({
        msg: error.message || "An error occurred",
        msgType: "error",
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleotpsubmit();
    }
  };

  const obfuscateEmail = (email) => {
    const [username, domain] = email.split("@");
    const obfuscatedUsername =
      username.charAt(0) +
      "x".repeat(username.length - 2) +
      username.charAt(username.length - 1);
    return obfuscatedUsername + "@" + domain;
  };

  const handleOrginization = (org) => {
    setSelectedOrg(org.value);
    setShowSign(true);
    cookies.set("organization", org.value);
    cookies.set("orgImage", org.imageUrl);
    setOrgImage(org.imageUrl);
  };

  return (
    <div className="loginMain">
      {!showSign && <SearchComponent onTitleClick={handleOrginization} />}

      {showSign && (
        <div className={classes.image}>
          <br />
          {/* <MatxSnackbar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMsg}
        messageType={messageType}
      /> */}
          <Grid container component={Paper} className={classes.layout}>
            <CssBaseline />
            <Grid item xs={false} sm={6} md={6} className={classes.sideimage}>
              <div className={classes.logintitle}>
                <h1>SIGN IN </h1>
              </div>
              <div className={classes.loginsubtitle}>Welcome back!</div>

              <div className={classes.redirctsignup}>
                {/* New to Collabkare ?{" "} */}
                <b>
                  <a
                    onClick={() => {
                      setOrgImage(null);
                      setShowSign(false);
                      setSelectedOrg("");
                      cookies.remove("organization");
                    }}
                    variant="body2"
                    style={{
                      color: "#007AFF",
                    }}
                  >
                    Choose Organization?
                  </a>
                </b>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} component={Paper} elevation={0}>
              <div className={classes.paper} elevation={10}>
                <img
                  className={classes.center}
                  src={orgImage == null ? logo : orgImage}
                  style={{ width: "292px", maxHeight: "115px" }}
                />
                <br />

                <div>
                  <div>
                    {!showOtp ? (
                      <form className="form" onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              label="Email"
                              name="email"
                              type="email"
                              size="small"
                              error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                              }
                              helperText={
                                formik.touched.email && formik.errors.email
                              }
                              onChange={formik.handleChange}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                              }}
                              value={formik.values.email}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <FormControl
                              className={clsx(
                                classes.margin,
                                classes.withoutLabel,
                                classes.textField
                              )}
                              variant="outlined"
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-password"
                                style={{ margin: "-3px -2px" }}
                              >
                                Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                size="small"
                                autoComplete="on"
                                margin="dense"
                                error={
                                  formik.touched.password &&
                                  Boolean(formik.errors.password)
                                }
                                helperText={
                                  formik.touched.password &&
                                  formik.errors.password
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                labelWidth={70}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <br />
                        <Button
                          variant="contained"
                          type="submit"
                          style={{
                            width: "100%",
                            backgroundColor: "#007AFF",
                            color: "white",
                          }}
                          disabled={showLoader}
                        >
                          {showLoader ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Sign In"
                          )}
                        </Button>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent={"space-between"}
                              sx={{ marginTop: "15px" }}
                            >
                              <div>
                                <a style={{ color: "#007AFF" }} href="/signup">
                                  Create Account
                                </a>
                              </div>
                              <div>
                                <a
                                  href="/forgotpassword"
                                  variant="body2"
                                  style={{ color: "#007AFF" }}
                                >
                                  Forgot password
                                </a>
                              </div>
                            </Stack>
                          </Grid>
                          <br />
                          <p
                            style={{
                              textAlign: "center",
                              color: "#747487",
                              paddingTop: "15px",
                            }}
                          >
                            By signing in, I agree to the{" "}
                            <a
                              style={{ color: "#007AFF" }}
                              target="_blank"
                              href="/privacyPolicy"
                            >
                              Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                              style={{ color: "#007AFF" }}
                              target="_blank"
                              href="/terms"
                            >
                              Terms of Service
                            </a>
                          </p>
                        </Grid>
                      </form>
                    ) : (
                      <div>
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          <b>2 step verification</b>
                          <CloseIcon
                            onClick={() => {
                              setShowOtp(false);
                            }}
                            style={{
                              float: "right",
                              backgroundColor: "#d5d5d5",
                              borderRadius: "20px",
                            }}
                          />
                        </h3>
                        <p
                          style={{
                            textAlign: "center",
                            color: "rgba(0, 0, 0, 0.68)",
                            fontSize: "15px",
                          }}
                        >
                          The extra step shows that it's really you trying to
                          sign in
                        </p>

                        <div
                          style={{
                            display: "flex",
                            marginBottom: "10px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <img
                              style={{
                                height: "45px",
                              }}
                              src={PhonePng}
                            ></img>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <p
                              style={{
                                textAlign: "-webkit-left",
                                color: "rgba(0, 0, 0, 0.68)",
                                fontSize: "15px",
                              }}
                            >
                              {" "}
                              Enter the Verification code we sent to{" "}
                              {otpSent
                                ? obfuscateEmail(formik?.values.email) + " "
                                : "your "}{" "}
                              acoount
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            float: "right",
                            color: "#007aff",
                            marginTop: "25px",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid xs={12} md={12} sm={12}>
                              <TextField
                                variant="outlined"
                                fullWidth
                                label="Verification Code"
                                name="otp"
                                type="number"
                                size="small"
                                onChange={(e) => {
                                  setOtpVal(e.target.value);
                                }}
                                value={otpVal}
                                onKeyDown={handleKeyDown}
                              />
                              <Button
                                variant="outlined"
                                onClick={handleotpsubmit}
                                style={{
                                  width: "100%",
                                  backgroundColor: "#007AFF",
                                  color: "white",
                                  float: "right",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                disabled={showLoader}
                              >
                                {showLoader ? (
                                  <CircularProgress size={24} color="inherit" />
                                ) : (
                                  " Submit"
                                )}
                              </Button>
                            </Grid>
                            <Grid xs={12} md={12} sm={6}>
                              <div>
                                {/* <Button style={{ color: "#007aff" }}>
                                  use another method
                                </Button> */}

                                <div
                                  style={{
                                    float: "right",
                                    color: "#007aff",
                                    marginTop: "8px",
                                  }}
                                >
                                  <OtpTimer
                                    textColor="rgba(0, 0, 0, 0.68)"
                                    style={{
                                      color: "rgba(0, 0, 0, 0.68)",
                                      fontSize: "15px",
                                    }}
                                    ButtonText={
                                      <span
                                        style={{
                                          backgroundColor: "none",
                                        }}
                                        onClick={() => {
                                          setOtpSent(false);
                                          sendOtpVerification();
                                        }}
                                      >
                                        Resend OTP
                                      </span>
                                    }
                                    seconds={59}
                                    minutes={0}
                                    text={""}
                                    resend={() => {
                                      setOtpSent(false);
                                      sendOtpVerification();
                                    }}
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      <CollabKareSnackbar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMsg.msg}
        messageType={snackbarMsg.msgType}
      />
    </div>
  );
};

export default Login;
