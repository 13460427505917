// PrivateSection.js

import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Column, Row } from 'simple-flexbox';
import { SidebarComponent, SidebarContext } from 'components/Sidebar';
import HeaderComponent from 'components/Header';
import PrivateRoutes from './PrivateRoutes';
import Footer from 'components/Footer';

const useStyles = createUseStyles({
    container: {
        height: '100%',
    },
    mainBlock: {
        marginLeft: 255,
        padding: '5px 10px',
        '@media (max-width: 1080px)': {
            marginLeft: 0
        }
    },
    contentBlock: {
        marginTop: 10,
        marginBottom: 50, // Add space for the footer
    },
});

function PrivateSection({ userRole }) {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <SidebarContext>
            <Row className={classes.container}>
                <SidebarComponent />
                <Column flexGrow={1} className={classes.mainBlock}>
                    <HeaderComponent />
                    <div className={classes.contentBlock}>
                        <PrivateRoutes userRole={userRole} />
                    </div>
                </Column>
            </Row>
            <Footer />
        </SidebarContext>
    );
}

export default PrivateSection;
