import React,{useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/routesConstants';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import { connect } from 'react-redux';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';



function SidebarComponent({userRole}) {




    const { push } = useHistory();
    const isMobile = window.innerWidth <= 1080;

   

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={SLUGS.dashboard}
                title='Overview'
                icon={DashboardIcon}
                onClick={() => onClick(SLUGS.dashboard)}
            />
           
            <MenuItem
                id={SLUGS.profile}
                title='Profile'
                icon={PersonIcon}
                onClick={() => onClick(SLUGS.profile)}
            />
             <MenuItem
                id={SLUGS.security}
                title='Security'
                icon={SecurityIcon}
                onClick={() => onClick(SLUGS.security)}
            />
             <MenuItem
                id={SLUGS.licenses}
                title='Licenses'
                icon={VerifiedUserIcon}
                onClick={() => onClick(SLUGS.licenses)}
            />
            {userRole?.includes(13) &&
             <MenuItem
                id={SLUGS.subscriptions}
                title='Store'
                icon={LocalGroceryStoreIcon}
                onClick={() => onClick(SLUGS.subscriptions)}
            />
            }
            <MenuItem
                id={SLUGS.subscriptions}
                title='Subscriptions'
                icon={MonetizationOnIcon}
                onClick={() => onClick(SLUGS.subscriptions)}
            />
           
            <MenuItem
                id={SLUGS.support}
                title='Support'
                icon={SupportAgentIcon}
                onClick={() => onClick(SLUGS.support)}
            />
        </Menu>
        
    );
}

const mapStateToProps = (state) => ({
    userRole:state.userRole
});

export default connect(mapStateToProps)(SidebarComponent);

