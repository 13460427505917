import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CollabKareSnackbar(props) {
  const { open, setOpen, message,messageType="success" } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getSeverity = () => {
    switch (messageType) {
      case "success":
        return "success";
      case "error":
      case "danger":
        return "error";
      default:
        return "success";
    }
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={getSeverity()}
          sx={{
            margin: "50px !important",
            width: "100%",
            color: "#ffff !important",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
