import React, { useEffect, useState } from "react";
import { Row } from "simple-flexbox";
import { createUseStyles, useTheme } from "react-jss";
import logo from "../../assets/icons/logo.svg";
import Cookies from "universal-cookie";

const useStyles = createUseStyles((theme) => ({
  container: {
    marginLeft: 32,
    marginRight: 32,
  },
  title: {
    ...theme.typography.cardTitle,
    color: theme.color.grayishBlue,
    opacity: 0.7,
    marginLeft: 12,
  },
}));

function LogoComponent() {
  const cookies = new Cookies();

  let [orgImage, setOrgImage] = useState(null);

  useEffect(() => {
    setOrgImage(cookies.get("orgImage"));
  }, []);

  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Row className={classes.container} horizontal="center" vertical="center">
      <img src={orgImage!=null?orgImage:logo} alt="logo" height={'80px'}/>
    </Row>
  );
}

export default LogoComponent;
