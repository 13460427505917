import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useFormik } from "formik";
import * as Yup from "yup";
import Cookies from "universal-cookie";
import { BASE_URL, forgotUserPassword } from "apiServices/apiConfig";
import { getDataPublic } from "resources/utilities";
import CollabKareSnackbar from "components/Snackbar";
import logo from "../../assets/icons/logo.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    // backgroundColor: theme.palette.background.dark,
  },
}));
const ForgotPassword = () => {
  const cookies = new Cookies();
  const classes = useStyles();
  let orgImage = cookies.get("orgImage");
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email  is required")
        .email("Invalid email address"),
    }),
    onSubmit: async (values, { resetForm }) => {
      forgotPassword(values?.email);
      resetForm();
    },
  });

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({ msg: "", msgType: "" });
  const [showLoader, setShowLoader] = useState(false);

  const forgotPassword = async (email) => {
    let orgName = cookies.get("organization");
    let url =
      BASE_URL + "/realms/" + orgName + `/emails/${email}` + forgotUserPassword;
    getDataPublic(url, function (res) {
      if (
        res &&
        res.type == "success" &&
        res.data == "Forgot Password Email Is Sent Successfully."
      ) {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: res.data,
          msgType: "success",
        });
      } else {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: res.data || "An error occurred",
          msgType: "error",
        });
      }
      setShowLoader(false);
    });

    // try {
    //     await auth.sendPasswordResetEmail(email);
    //     alert('Password reset email sent!');
    //     // Clear email field and handle any navigation or state updates
    //   } catch (error) {
    //     console.error(error);
    //     alert('Error sending password reset email:', error.message);
    //   }
  };

  return (
    <div className={classes.root}>
      <div className={classes.paper} elevation={10}>
        <img
          className={classes.center}
          src={orgImage == null ? logo : orgImage}
          style={{ width: "292px", maxHeight: "115px" }}
        />
        <br />
      </div>
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              Forgot Your Password?
            </Typography>
            <form
              className={classes.form}
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <TextField
                variant="outlined"
                required
                fullWidth
                size="small"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
                value={formik.values.email}
              />

              <Button
                type="submit"
                fullWidth
                variant="outlined"
                style={{
                  width: "100%",
                  backgroundColor: "#007AFF",
                  color: "white",
                  float: "right",
                  margin: "auto",
                  marginTop: "10px",
                }}
                className={classes.submit}
                disabled={showLoader}
              >
                {showLoader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  " Submit"
                )}
              </Button>
              <Grid container>
                <Grid item>
                  <Link to="/login" style={{ color: "rgb(0, 122, 189)" }}>
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <p style={{ textAlign: "center" }}>
            Enter your email address and we will send you instructions on how to
            create a new password.
          </p>
        </Container>
        <CollabKareSnackbar
          open={showSnackbar}
          setOpen={setShowSnackbar}
          message={snackbarMsg.msg}
          messageType={snackbarMsg.msgType}
        />
      </div>
    </div>
  );
};
export default ForgotPassword;
