// SearchComponent.jsx

import React, { useState, useEffect } from "react";
import { InputAdornment, Input, Typography, Box, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../../assets/icons/logo.svg";
import hospitaldummy from "../../assets/icons/hospitaldummy.png";
import { storage } from "auth/firebase";
import { getDataPublic } from "resources/utilities";
import { getAllRealms } from "apiServices/apiConfig";
import Loader from "components/loading/loader";

const SearchComponent = ({ onTitleClick }) => {
  const [tenantsData, setTenantsData] = useState();
  const [initialData, setInitialData] = useState();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    getTenants();
  }, []);

  useEffect(() => {
    const orgNameFromUrl = window.location.pathname.split("/").pop();

    // Check if the URL path starts with "/login/" and if initialData is set
    if (window.location.pathname.startsWith("/login/") && initialData) {
      const filteredOrg = initialData.filter((item) =>
        item.label.toLowerCase().includes(orgNameFromUrl.toLowerCase())
      );
      setTenantsData(filteredOrg);

      // Trigger the onTitleClick function automatically
      if (filteredOrg.length > 0) {
        onTitleClick(filteredOrg[0]);
      }
    } else {
      setTenantsData(initialData || []); // Ensure tenantsData is set even if initialData is not
    }
  }, [initialData, onTitleClick]);

  const getTenants = async () => {
    let tempArr = [];
    setShowLoader(true);
    getDataPublic(getAllRealms, function (res) {
      if (res && res.type == "success") {
        res &&
          res?.data?.length > 0 &&
          res.data.forEach(async (tenant) => {
            if (tenant !== "") {
              // Get the image URLs for each tenant
              const imageUrls = await fetchImages(tenant);
              tempArr.push({
                label: tenant,
                value: tenant,
                imageUrl: imageUrls.length > 0 ? imageUrls[0] : logo, // Assuming you want the first image URL
              });

              tempArr.sort((a, b) => a.label.localeCompare(b.label));
              setInitialData([...tempArr]);
              setTenantsData([...tempArr]); // Use spread operator to create a new array
              setShowLoader(false);
            }
          });
      }else{
        
      }
    });
  };

  const fetchImages = async (tenantId) => {
    try {
      let result = await storage
        .ref()
        .child(`documents/org_dp/${tenantId}/`)
        .listAll();
      let urlPromises = result.items.map((imageRef) =>
        imageRef.getDownloadURL()
      );
      return Promise.all(urlPromises);
    } catch (error) {
      console.error(error.message);
      return []; // Return an empty array in case of an error
    } finally {
    }
  };

  const handleSearch = (e) => {
    if (e && e.target.value) {
      const filtered = tenantsData.filter((item) =>
        item.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setTenantsData(filtered);
    } else {
      setTenantsData(initialData);
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            width: "100%",
            maxWidth: 400,
            textAlign: "center",
            boxShadow: "none",
            background: "none",
          }}
        >
          <img src={logo} />
          {/* <span>
            Search for your organization from the list below. If you are not
            affiliated with an organization Please register your orginixzation
            with us
          </span> */}
          <Input
            variant="outlined"
            sx={{
              marginTop: "35px",
            }}
            placeholder="Search orginization"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            fullWidth
            onChange={handleSearch}
          />
        </Paper>

        <Paper
          elevation={3}
          sx={{
            p: 2,
            mt: 2,
            width: "100%",
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          {tenantsData ? (
            tenantsData?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  p: 1,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #dbcece",
                }}
                onClick={() => onTitleClick(item)}
              >
                <img
                  src={item.imageUrl != null ? item.imageUrl : hospitaldummy}
                  alt={item.lable}
                  style={{ width: "45px", height: "45px", marginRight: "25px" }}
                />
                <div style={{ fontSize: "16px", fontWeight: 500 }}>
                  {item.label?.toUpperCase()}
                </div>
              </Box>
            ))
          ) : (
            <div>No Orginization data </div>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default SearchComponent;
