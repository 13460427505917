import React, { useEffect } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import db, { functions } from "auth/firebase";

export default function SetUpUser() {
  const urlParams = new URLSearchParams(window.location.search);
  const org = urlParams.get("org");
  const user = urlParams.get("user");
  const userId = urlParams.get("userId");
  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const baseUrl = urlObject.origin;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (org) {
          if (userId) {
            const userRef = db.collection("Registration").doc(userId);
            const userDoc = await userRef.get();
            if (userDoc.exists) {
              const userData = userDoc.data();
              await createUser(userData);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        // Handle the error as needed
      }
    };

    fetchData(); // Call the async function

    // You can return a cleanup function here if needed
  }, [org, userId]); // Include dependencies in the dependency array


  const createUser = async (data) => {
    let payload = {
      tenantId: org,
      email: data.email,
      password: data.password,
      phone: data.phone,
      userName: data.first_name + data.last_name,
    };
    let tenantCreation = await createTenantandUser(payload);
    if (tenantCreation?.success) {
        let tenantinfo = tenantCreation.userRecord;
        data = {
          ...data,
          tenantId: tenantinfo.tenantId,
          uid: tenantinfo.uid,
        };
        const createnewUser = functions.httpsCallable("createUser");
        const response = await createnewUser(data);
        let res = response.data;
        if (res.success == true || res.message =="User already exists") {
            window.open(`${baseUrl}/login`, "_self");
        } else {
            window.open(`${baseUrl}/signup`, "_self");
         
        }
      }
  };

  const createTenantandUser = async (data) => {
    try {
      const response = await fetch(
        "https://us-central1-collabkare-250512.cloudfunctions.net/createUserunderTenant",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tenantId:data?.tenantId,
            email: data?.email,
            password: data?.password,
            phone: data?.phone.replace(/[\s()-]/g, ""),
            userName: data?.userName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Error in createTenantandUser:", error);
      return { error: error.message };
    }
  };

  return (
    <>
      {/* <CirclesWithBar
        height="100"
        width="100"
        color="#4e88e1"
        outerCircleColor="#ff5248"
        innerCircleColor="#ffc114"
        barColor="#19cdca"
        ariaLabel="circles-with-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      /> */}
    </>
  );
}
