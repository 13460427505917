import React from "react";
import PropTypes from "prop-types";
import './button.css'

export default function Button(props) {
  const {
    name,
    buttonType,
    buttonColor,
    disableStatus,
    children,
    onClick,
    buttonTextColor,
    style,
  } = props;
  return (
    <>
      <button
        name={name}
        type={buttonType}
        className={`${buttonColor} ${buttonTextColor} btn `}
        disabled={disableStatus}
        onClick={onClick}
        style={style}
      >
        {children}
      </button>
    </>
  );
}

Button.defaultProps = {
  name: "",
  buttonType: "button",
  disableStatus: false,
  buttonTextColor: "",
  style: {},
};

Button.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  buttonType: PropTypes.string,
  buttonColor: PropTypes.string,
  disableStatus: PropTypes.bool,
  onClick: PropTypes.func,
  buttonTextColor: PropTypes.string,
  style: PropTypes.any,
};
