import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/material.css";
import {
  Grid,
  Select,
  TextField,
  Button,
  Link,
  CssBaseline,
  Paper,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./registration.css";
import db, { functions } from "auth/firebase"; // Update with your Firebase setup
import CollabKareSnackbar from "components/Snackbar";
import { Blocks } from "react-loader-spinner";
import Cookies from "universal-cookie";
import logo from "../../assets/icons/Asset-9.svg";
import { createDataPublic } from "resources/utilities";
import { preAccount } from "apiServices/apiConfig";

const CreateOrginization = () => {
  // const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
  const rawQuery = window.location.search;

  // Remove the leading '?' and decode the query string if URL-encoded
  const cleanedQuery = decodeURIComponent(rawQuery.substring(1));
  // Decode the cleaned Base64-encoded string
  const decodedString = atob(cleanedQuery);

  // Extract the key-value pairs from the decoded string
  const decodedParams = new URLSearchParams(decodedString);
  // Extract specific parameters
  const auth_code = decodedParams.get("accessCode"); // Retrieve 'accessCode' parameter
  const user = decodedParams.get("user"); // Retrieve 'user' paramete

  const [userInfo, setUserInfo] = useState();
  const [isValidated, setIsValidated] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({ msg: "", msgType: "" });
  const [showLoader, setShowLoader] = useState(false);
  let [org, setOrg] = useState("");
  let [noDomain, setNoDomain] = useState(false);
  const cookies = new Cookies();

  useEffect(() => {
    org = extractSubdomainFromEmail(user);
    setOrg(org);
    getUserInfo(user);
    if (org) {
      const forbiddenDomains = ["gmail", "mail", "yahoo", "rediff", "yopmail"];
      if (forbiddenDomains.includes(org?.toLocaleLowerCase())) {
        noDomain = true;
        setNoDomain(noDomain);
      }
    }
  }, []);

  function extractSubdomainFromEmail(email) {
    const regex = /@([a-zA-Z0-9.-]+)$/;
    const match = email.match(regex);
    const domain = match ? match[1] : null;

    if (domain) {
      const parts = domain.split(".");
      // Exclude the last part (TLD), return everything before it
      return parts.slice(0, -1).join(".");
    }

    return null;
  }

  const getUserInfo = async (email) => {
    let data = await db.collection("Registration").doc(email).get();
    setUserInfo(data.data());
  };

  const formik = useFormik({
    initialValues: {
      organizationName: "",
      organizationTaxId: "",
      tax: "",
      addressLine: "",
      area: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
    },
    validationSchema: Yup.object({
      organizationTaxId: Yup.string().required(
        "Organization Tax ID is required"
      ),
      tax: Yup.string().required("Tax is required"),
      addressLine: Yup.string().required("Address Line is required"),
      area: Yup.string().required("Area is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      country: Yup.string().required("Country is required"),
      pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^\d{6}$/, "Invalid pincode (should be 6 digits)"),
    }),
    onSubmit: async (values, { resetForm }) => {
      // setShowLoader(true);
      let payload = {
        id: 0,
        user: {
          id: 0,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: user,
          password: userInfo.password,
          phone: userInfo.phone,
          gender: userInfo.gender,
          mfaType: "",
        },
        organization: {
          id: values?.organizationTaxId,
          name: values.organizationName == "" ? org : values.organizationName,
          taxId: values?.tax,
          type: "",
          area: values?.area,
          date: new Date(),
          code: "",
          address: values?.addressLine,
          city: values?.city,
          zipcode: values?.pincode,
          region: values?.country,
          createdBy: user,
        },
        accesscode: auth_code,
        date: new Date(),
        active: true,
        product: {
          id: 0,
          name: "",
          description: "",
        },
      };
      updateUserInfo(payload);
      createDataPublic(preAccount, payload, function (res) {
        if (
          res &&
          res.type == "success" &&
          res.data !== "Requested Token Is Expired"
        ) {
          setSnackbarMsg({
            msg: "Orginization registred success",
            msgType: "success",
          });
          setShowSnackbar(true);
          updateUserInfo(payload);
          // setTimeout(() => {
          //   window.location.href = "/login";
          // }, 3000);
        } else {
          setShowSnackbar(true);
          setSnackbarMsg({
            msg: res.data,
            msgType: "error",
          });
        }
        setShowLoader(false);
      });
    },
  });

  const updateUserInfo = async (payload) => {
    let data = {
      ...userInfo,
      organization: org,
      addressLine: payload?.organization?.address,
      city: payload?.organization?.city,
      zipcode: payload?.organization?.zipcode,
      region: payload?.organization?.region,
      createdBy: user,
      taxId: payload?.organization?.taxId,
      type: "",
      area: payload?.organization?.area,
      date: new Date(),
    };
    await db.collection("UserInfo").doc(user).set(data);
    await db.collection("organization").doc(org).set(payload.organization);
  };

  return (
    <div>
      <Grid container component="main" className={"root"}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className={"image"} />
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          style={{ boxShadow: "none" }}
        >
          <div className={"paper"}>
            {showLoader ? (
              <Blocks
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper loading"
                visible={true}
              />
            ) : (
              <div>
                <div className={"paper12"}>
                  <a href="/start">
                    <img
                      src={logo}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "300px",
                        cursor: "pointer",
                      }}
                    ></img>
                  </a>
                </div>
                <h5>
                  <div className="h4Text">Create your organization</div>
                </h5>
                <form className={"form"} onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {noDomain ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="organization Name"
                          size="small"
                          id="organizationName"
                          name="organizationName"
                          error={
                            formik.touched.organizationName &&
                            Boolean(formik.errors.organizationName)
                          }
                          helperText={
                            formik.touched.organizationName &&
                            formik.errors.organizationName
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.organizationName}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="organization Name"
                          size="small"
                          id="organizationName"
                          name="organizationName"
                          onBlur={formik.handleBlur}
                          value={org}
                          disabled={true}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Organization Tax Id"
                        name="organizationTaxId"
                        placeholder="Your organization Tax Id"
                        size="small"
                        error={
                          formik.touched.organizationTaxId &&
                          Boolean(formik.errors.organizationTaxId)
                        }
                        helperText={
                          formik.touched.organizationTaxId &&
                          formik.errors.organizationTaxId
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.organizationTaxId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Tax Number"
                        name="tax"
                        placeholder="Your tax number"
                        size="small"
                        error={formik.touched.tax && Boolean(formik.errors.tax)}
                        helperText={formik.touched.tax && formik.errors.tax}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.tax}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="addressLine"
                        name="addressLine"
                        placeholder="Your Address Line"
                        size="small"
                        error={
                          formik.touched.addressLine &&
                          Boolean(formik.errors.addressLine)
                        }
                        helperText={
                          formik.touched.addressLine &&
                          formik.errors.addressLine
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.addressLine}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Area"
                        name="area"
                        placeholder="Area"
                        size="small"
                        error={
                          formik.touched.area && Boolean(formik.errors.area)
                        }
                        helperText={formik.touched.area && formik.errors.area}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.area}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="State"
                        name="state"
                        placeholder="state"
                        size="small"
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="City"
                        name="city"
                        placeholder="City"
                        size="small"
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Country"
                        name="country"
                        placeholder="country"
                        size="small"
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Pincode"
                        name="pincode"
                        placeholder="pincode"
                        size="small"
                        error={
                          formik.touched.pincode &&
                          Boolean(formik.errors.pincode)
                        }
                        helperText={
                          formik.touched.pincode && formik.errors.pincode
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.pincode}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent={"flex-end"}
                        sx={{ marginTop: "15px" }}
                      >
                        <div>
                          <Button variant="contained" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </div>
          <CollabKareSnackbar
            open={showSnackbar}
            setOpen={setShowSnackbar}
            message={snackbarMsg.msg}
            messageType={snackbarMsg.msgType}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateOrginization;
