export const BASE_URL =`${process.env.REACT_APP_ACCOUNT_API}`
export const checkRealms= `${BASE_URL}/check`
export const presignup= `${BASE_URL}/presignup?url=`
export const preAccount= `${BASE_URL}/preaccount`
export const getAllRealms= `${BASE_URL}/realms`
export const forgotUserPassword= '/forgotPassword'
export const updateUserPassword ='/updatePassword'
export const userLogin = `${BASE_URL}/accesstoken?realm=`
export const sendOtp= `/sendOTP`
export const otpAuthenticator= `${BASE_URL}/otpAuthenticator`
export const patientSignup= `sendCreateUserEmail`
export const userExists =`/email`
