import React, { useEffect, useState } from "react";
import { string } from "prop-types";
import { useHistory } from "react-router-dom";
import { Row } from "simple-flexbox";
import { createUseStyles, useTheme } from "react-jss";
import SLUGS from "resources/routesConstants";
import { IconBell } from "assets/icons";
import DropdownComponent from "components/Dropdown";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import AppsIcon from "@mui/icons-material/Apps";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "components/Button";
import { useSelector, useDispatch } from "react-redux";
import { storage } from "auth/firebase";
import { logoutUser } from "../../redux/action-creators/auth";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import kareManager from "../../assets/appicons/kareManager.png";
import KareMD from "../../assets/appicons/kareMD.png";
import KarePortal from "../../assets/appicons/karePortal.png";
import KarePractice from "../../assets/appicons/karePractice.png";
import KareIntelligence from "../../assets/appicons/kareIntelligence.png";
import { checkExistingSession } from "../../redux/action-creators/auth";

const useStyles = createUseStyles((theme) => ({
  avatar: {
    height: 35,
    width: 35,
    minWidth: 35,
    borderRadius: 50,
    marginLeft: 14,
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    "@media (max-width: 768px)": {
      marginLeft: 14,
    },
  },
  container: {
    height: 40,
  },
  name: {
    ...theme.typography.itemTitle,
    textAlign: "right",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  separator: {
    borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
    marginLeft: 32,
    marginRight: 32,
    height: 32,
    width: 2,
    "@media (max-width: 768px)": {
      marginLeft: 14,
      marginRight: 0,
    },
  },
  title: {
    ...theme.typography.title,
    "@media (max-width: 1080px)": {
      marginLeft: 50,
    },
    "@media (max-width: 468px)": {
      fontSize: 20,
    },
  },
  iconStyles: {
    cursor: "pointer",
    marginLeft: 25,
    "@media (max-width: 768px)": {
      marginLeft: 12,
    },
  },
}));

function HeaderComponent() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const userData = useSelector((state) => state.userData);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [userInfo, setUserInfo] = useState();
  let [profilePic, setProiflePic] = useState();
  const [appsData, setAppsData] = useState([]);


  let icons = [
    {
      title: "KareMD",
      image: KareMD,
      link: "https://karemd.collabkare.com/",
    },
    {
      title: "KarePortal",
      image: KarePortal,
      link: "https://kareportal.collabkare.com/",
    },
    {
      title: "KarePractice",
      image: KarePractice,
      link: "https://karepractice.collabkare.com/",
    },
    {
      title: "KareManager",
      image: kareManager,
      link: "https://karemanager.collabkare.com/",
    },
    {
      title: "KareIntelligence",
      image: KareIntelligence,
      link: "https://karemanager.collabkare.com/",
    },
  ];

  useEffect(() => {
    if (userData) {
      setUserInfo(userData?.data?.data[0]);
      getUserPic(userData?.data?.data[0]?.email);
      getAppsData(userData?.data?.data[0]?.delegatedApplications);
    }
  }, [userData]);


  const fetchImages = async (email) => {
    let result = await storage
      .ref()
      .child(`documents/users_dp/${email}/`)
      .listAll();
    let urlPromises = result.items.map((imageRef) => imageRef.getDownloadURL());
    return Promise.all(urlPromises);
  };

  const getAppsData = async (data) => {
    const allocatedApps =
      data?.length > 0 || data != undefined
        ? await filterAllocatedApps(data)
        : [];
    filterIconsByRoles(allocatedApps);
  };

  function filterAllocatedApps(permissions) {
    return [
      ...new Set(permissions.map((permission) => permission.split("_")[0])),
    ];
  }

  function filterIconsByRoles(uniqueRoles) {
    let filtItems = icons.filter((icon) => uniqueRoles.includes(icon.title));
    setAppsData(filtItems);
  }

  const getUserPic = async (email) => {
    const urls = await fetchImages(email);
    if (urls?.length > 0) {
      profilePic = urls[0];
      setProiflePic(profilePic);
    }
  };

  function getLogout() {
    return (
      <>
        <div className="flex-space" onClick={() => dispatch(logoutUser())}>
          <span>Logout</span>
          <span>
            <LogoutIcon />
          </span>
        </div>
      </>
    );
  }

  function getDiv() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <div className="flex-centre padding10">
              <Avatar
                sx={{ bgcolor: "#ff5722", height: "75px", width: "75px" }}
                alt="RemySharp"
                src={profilePic}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <span>{userInfo?.name}</span> <br />
            <span>
              <b>{userInfo?.email}</b>
            </span>
            <br />
            <Button
              buttonColor={"secondaryBtn"}
              onClick={() => {
                push(SLUGS.profile);
              }}
              style={{ marginTop: "5px" }}
            >
              Manage Profile
            </Button>
            <br />
          </Grid>
        </Grid>
      </>
    );
  }

  function getApps() {
    return (
      <>
        {appsData.length > 0 ? (
          <div>
            {appsData.map((item, index) => (
              <div className={"appIcons"}>
                <span style={{ padding: "10px" }}>
                  <img
                    alt={item.title}
                    src={item.image}
                    style={{
                      width: "95px", // Adjust the width as needed
                      height: "45px", // Adjust the height as needed
                      margin: "auto",
                      overflow: "hidden",
                      borderRadius: "8px", // Adjust the borderRadius as needed
                    }}
                  />
                </span>
                <span style={{ paddingTop: "20px" }}>{item.title}</span>
                <span
                  style={{ padding: "20px" }}
                  onClick={() => {
                    window.location.href = item.link;
                  }}
                >
                  {<ArrowOutwardIcon />}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div> No Allocated Apps</div>
        )}
      </>
    );
  }

  return (
    <Row
      className={classes.container}
      vertical="center"
      horizontal="space-between"
    >
      <span className={classes.title}>{""}</span>
      <Row vertical="center">
        <div className={classes.iconStyles}>
          <DropdownComponent
            label={<IconBell />}
            options={[
              {
                label: "No Notifications",
                onClick: () => console.log("Notification #4"),
              },
            ]}
            position={{
              top: 42,
              right: -14,
            }}
          />
        </div>
        <div className={classes.iconStyles}>
          <DropdownComponent
            label={<AppsIcon />}
            options={[
              {
                label: getApps(),
                onClick: "",
              },
            ]}
            position={{
              top: 42,
              right: -14,
            }}
          />
        </div>
        <div className={classes.separator}></div>
        <DropdownComponent
          label={
            <>
              <span className={classes.name}>{userInfo?.name}</span>
              <span className={classes.avatar}>
                <Avatar
                  sx={{ bgcolor: "#ff5722", height: "35px", width: "35px" }}
                  alt="RemySharp"
                  src={profilePic}
                />
              </span>
            </>
          }
          options={[
            {
              label: getDiv(),
              onClick: "",
            },
            {
              label: getLogout(),
              onClick: "",
            },
          ]}
          position={{
            top: 52,
            right: -6,
          }}
        />
      </Row>
    </Row>
  );
}

HeaderComponent.propTypes = {
  title: string,
};

export default HeaderComponent;
