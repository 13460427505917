import axios from "axios";
import { jwtDecode } from 'jwt-decode';
const accessToken = sessionStorage.getItem("access_token"); // store the access token in localStorage

const headers = {
  Authorization: `Bearer ${accessToken}`,
  "Content-Type": "application/json",
};

const publicHeaders={
  "Content-Type": "application/json",
}

const userHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded', // Ensure the correct content type
};

export const createData = async (url, payload, callback) => {
  if (!accessToken || !checkIsValidToken(accessToken)) {
    // Redirect to login or handle expired token
    window.location.href = "/login"; // Redirect to your login screen
    return;
  }
  try {
    const response = await axios.post(url, payload, { headers });
    callback(null, {
      type: "success",
      data: response.data,
      status: response.status,
    });
  } catch (error) {
    callback({
      type: "error",
      data: error.response.data,
      status: error.response.status,
    });
  }
};

export const getData = async (url, callback) => {
  if (!accessToken || !checkIsValidToken(accessToken)) {
    // Redirect to login or handle expired token
    window.location.href = "/login"; // Redirect to your login screen
    return;
  }
  try {
    const response = await axios.get(url, { headers });
    callback({
      type: "success",
      data: response.data,
      status: response.status,
    });
  } catch (error) {
    checkIsValidToken();
    callback({
      type: "error",
      data: error.response.data,
      status: error.response.status,
    });
  }
};

export const createDataPublic = async (url, payload, callback) => {
  try {
    const response = await axios.post(url, payload, { publicHeaders });
    callback({
      type: "success",
      data: response.data,
      status: response.status,
    });
  } catch (error) {
    callback({
      type: "error",
      data: error.response.data.error,
      status: error.response.data.status,
    });
  }
};

export const getDataPublic = async (url, callback) => {
  try {
    const response = await axios.get(url, { publicHeaders });
    callback({
      type: "success",
      data: response.data,
      status: response.status,
    });
  } catch (error) {
    callback({
      type: "error",
      data: error.response.data,
      status: error.response.status,
    });
  }
};

export const putDataPublic = async (url, payload, callback) => {
  try {
    const response = await axios.put(url, payload, { publicHeaders });
    callback({
      type: "success",
      data: response.data,
      status: response.status,
    });
  } catch (error) {
    callback({
      type: "error",
      data: error.response.data.error,
      status: error.response.data.status,
    });
  }
};

export const sendPostRequest = async (url, data,callback) => {
  try {
    // Send POST request with the specified URL, data, and headers
    const response = await axios.post(url, data, { userHeaders });

    // Call the callback function with success data and status
    callback({
      type: 'success',
      data: response.data,
      status: response.status,
    });
  } catch (error) {
    // Handle potential errors
    const errorMessage = error.response ? error.response.data.error : 'Unknown error';
    const errorStatus = error.response ? error.response.status : 500; // Default to 500 if status is not available

    // Call the callback function with error data and status
    callback({
      type: 'error',
      data: errorMessage,
      status: errorStatus,
    });
  }
};


const checkIsValidToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000 > Date.now();
  } catch (error) {
    return false;
  }
};

export function convertSlugToUrl(slug, parameters) {
  let url = slug;
  Object.entries(parameters).forEach(([key, value]) => {
    url = url.replace(`:${key}`, value);
  });
  return url;
}
