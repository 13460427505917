import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../../assets/icons/Asset-29.svg";
import "react-phone-input-2/lib/material.css";
import {
  Grid,
  TextField,
  Button,
  Link,
  CssBaseline,
  Paper,
  Stack,
  FormControl,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import "./registration.css";
import db from "auth/firebase";
import BasicModal from "components/BasicModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PasswordStrengthBar from "react-password-strength-bar";
import CollabKareSnackbar from "components/Snackbar";
import { Blocks } from "react-loader-spinner";
import Cookies from "universal-cookie";
import { checkRealms, presignup } from "apiServices/apiConfig";
import { getDataPublic, createDataPublic } from "resources/utilities";

const Registration = () => {
  const cookies = new Cookies();
  let [organization, setOrganization] = useState("");
  const [isOrgExists, setIsOrgExists] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({ msg: "", msgType: "" });
  const [showLoader, setShowLoader] = useState(false);
  const [messageType, setMessageType] = useState("success");
  const formik = useFormik({
    initialValues: {
      email: "",
      confirmationemail: "",
      firstName: "",
      lastName: "",
      phone: "",
      password: "",
      passwordconfirm: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email  is required"),
      confirmationemail: Yup.string()
        .required("Confirm your email")
        .oneOf([Yup.ref("email"), null], "Emails must match"),
      firstName: Yup.string().required("First Name Line is required"),
      lastName: Yup.string().required("Last Name is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must be AplphaNumberic with one special character and one UpperCase at least 8 characters long"
        ),
      passwordconfirm: Yup.string()
        .required("Confirm your password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setShowLoader(true);
      createUser(values);
      if (!isOrgExists) {
        createUser(values);
        resetForm();
      } else {
        setShowLoader(false);
        setModalOpen(true);
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  function extractSubdomainFromEmail(email) {
    const regex = /@([a-zA-Z0-9.-]+)$/;
    const match = email.match(regex);
    const domain = match ? match[1] : null;

    if (domain) {
      const parts = domain.split(".");
      return parts.slice(0, -1).join(".");
    }

    return null;
  }

  const validateTenat = async (data) => {
    setShowLoader(true);
    organization = await extractSubdomainFromEmail(data);
    let url = checkRealms + `/${organization}`;
    getDataPublic(url, function (res) {
      if (res && res.type == "success") {
        if (res.data == "realm existed") {
          setIsOrgExists(true);
          setSnackbarMsg({
            msg: "Orginization already exists",
            msgType: "error",
          });
          setShowSnackbar(true);
        }
      } else {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: res.data || "An error occurred",
          msgType: "error",
        });
      }
      setShowLoader(false);
    });
  };

  const createUser = async (data) => {
    const payload = {
      id: 0,
      user: {
        id: 0,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        phone: data.phone,
        gender: "",
        mfaType: "",
      },
      organization: {
        id: 0,
        name: "",
        taxId: "",
        type: "",
        phone: "",
        date: new Date(),
        code: "",
        address: "",
        city: "",
        zipcode: "",
        region: "",
        createdBy: "",
      },
      accesscode: "",
      date: new Date(),
      active: true,
      product: {
        id: 0,
        name: "",
        description: "",
      },
    };
    let url = presignup + `${data.email}`;
    createDataPublic(url, payload, function (res) {
      if (res && res.type == "success") {
        setSnackbarMsg({
          msg: "Verification Email Sent Successfully! ",
          msgType: "success",
        });
        setShowSnackbar(true);
        updateUserInfo(data);
      } else {
        setShowSnackbar(true);
        setSnackbarMsg({
          msg: res.data,
          msgType: "error",
        });
      }
      setShowLoader(false);
    });
  };
  
  const updateUserInfo = async (payload) => {
    await db.collection("Registration").doc(payload.email).set(payload);
  };

  {
    /*  const landLoginPage = () => {
    setIsOrgExists(false);
    window.open(`${window._env_.myAccountDefault}/login`, "_self");
  }; */
  }

  return (
    <div>
      <CollabKareSnackbar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMsg.msg}
        messageType={snackbarMsg.msgType}
      />
      <Grid container component="main" className={"root"}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className={"image"} />
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          style={{ boxShadow: "none" }}
        >
          <div className={"paper"}>
            {showLoader ? (
              <Blocks
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper loading"
                visible={true}
              />
            ) : (
              <div>
                <div className={"paper12"}>
                  <img
                    src={logo}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "300px",
                    }}
                  ></img>
                </div>
                <h4 style={{ textAlign: "center" }}>
                  {" "}
                  YOUR JOURNEY STARTS HERE
                </h4>
                <p style={{ textAlign: "center" }}>
                  Enjoy unlimited features for free for 30days. Start your free
                  trial today or
                  <Link to="#" style={{ color: "#007AFF" }}>
                    {" "}
                    book a demo
                  </Link>
                </p>
                <h5>
                  <div className="h4Text">Create my account</div>
                </h5>
                <form className={"form"} onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="First Name"
                        size="small"
                        id="firstName"
                        name="firstName"
                        placeholder="Your first name"
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Last Name"
                        name="lastName"
                        placeholder="Your last name"
                        size="small"
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Email"
                        name="email"
                        placeholder="Your email address"
                        type="email"
                        size="small"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={
                          formik.touched.email &&
                          (formik.errors.email ||
                            (isOrgExists && (
                              <span className={"passwordValidation"}>
                                Your Orginization is already exists with us
                              </span>
                            )))
                        }
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          validateTenat(e.target.value);
                        }}
                        value={formik.values.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Confirm Email"
                        name="confirmationemail"
                        placeholder="Your email address"
                        type="email"
                        size="small"
                        error={
                          formik.touched.confirmationemail &&
                          Boolean(formik.errors.confirmationemail)
                        }
                        helperText={
                          formik.touched.confirmationemail &&
                          formik.errors.confirmationemail
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmationemail}
                        onPaste={(e) => e.preventDefault()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        className={"margin"}
                        variant="outlined"
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          size="small"
                          variant="outlined"
                          autoComplete="password"
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className={"passwordValidation"}>
                            {formik.errors.password}
                          </div>
                        )}
                        <PasswordStrengthBar
                          password={formik.values.password}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="passwordconfirm"
                        label="Confirm Password"
                        type="password"
                        error={
                          formik.touched.passwordconfirm &&
                          Boolean(formik.errors.passwordconfirm)
                        }
                        helperText={
                          formik.touched.passwordconfirm &&
                          formik.errors.passwordconfirm
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwordconfirm}
                        onPaste={(e) => e.preventDefault()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PhoneInput
                        country={"us"}
                        className={"reacttelinput"}
                        name="phone"
                        required
                        size="small"
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                        onChange={(value, data, event, formattedValue) => {
                          formik.setFieldValue("phone", formattedValue);
                          formik.setTouched({ ...formik.touched, phone: true });
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        variant="outlined"
                        label="Phone number"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent={"flex-end"}
                        sx={{ marginTop: "15px" }}
                      >
                        <div>
                          <Link
                            href="/login"
                            variant="body2"
                            style={{ color: "#007AFF", marginLeft: "90px" }}
                          >
                            Sign in instead
                          </Link>
                        </div>
                        <div>
                          <Button variant="contained" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </div>
        </Grid>
        <BasicModal
          title={"Information"}
          modalOpen={modalOpen}
          setModalOpen={() => setModalOpen(!modalOpen)}
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
        >
          <div>
            {`${organization} is alreay exists in our database. Please do login`}
          </div>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"flex-start"}
            sx={{ marginTop: "15px" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // onClick={landLoginPage}
            >
              Proceed
            </Button>
            <Button
              type="submit"
              variant="outlined"
              onClick={() => {
                setModalOpen(!modalOpen);
              }}
            >
              Close
            </Button>
          </Stack>
        </BasicModal>
      </Grid>
    </div>
  );
};

export default Registration;
